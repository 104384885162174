<template>
  <el-main>
    <div class="topTips">
      <div>此为跨境电商PC网站的装修部分，PC端显示英文，请下面内容添加英文</div>
      <div>
        网站地址：
         <el-link type="primary" href="http://shop518423.mall.qqweisou.cn" target="_blank">http://shop518423.mall.qqweisou.cn</el-link>
      </div>
    </div>
    <el-form :model="info" status-icon :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="店铺名称：" prop="store_name">
        <el-input v-model="info.store_name"></el-input>
      </el-form-item>
      <el-form-item label="店铺logo：" prop="store_logo">
        <ReadyUploadSource
          :path="info.store_logo"
          @getSource="(val) => (info.store_logo = val.path)"
          @removeThis="info.store_logo = ''"
          :showStyle="{
            width: '200px',
            height: '200px',
          }"
        ></ReadyUploadSource>
        <div class="sizeTips">建议图片尺寸200*200px，大小不超过1M</div>
      </el-form-item>
      <el-form-item label="商家分类：" prop="store_classify_id">
        <el-select v-model="info.store_classify_id" placeholder="请选择">
          <el-option v-for="item in storeClassify" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺banner：" prop="store_banner">
        <ReadyUploadSource
          :isMany="true"
          :manyPath="info.store_banner"
          @changeOrder="(list) => (info.store_banner = list)"
          @getSource="(list) => info.store_banner.push(...list)"
          @removeThis="(index) => info.store_banner.splice(index, 1)"
          :showStyle="{
            width: '384px',
            height: '120px',
          }"
        ></ReadyUploadSource>
        <div class="sizeTips">建议图片尺寸1920*600px，大小不超过1M</div>
      </el-form-item>
      <el-form-item label="公司简介："  prop="store_intro">
        <el-input type="textarea" :rows="7" v-model="info.store_intro"></el-input>
      </el-form-item>
      <el-form-item label="店铺装饰图："  prop="store_ornament_picture">
        <ReadyUploadSource
          :path="info.store_ornament_picture"
          @getSource="(val) => (info.store_ornament_picture = val.path)"
          @removeThis="info.store_ornament_picture = ''"
          :showStyle="{
            width: '384px',
            height: '120px',
          }"
        ></ReadyUploadSource>
        <div class="sizeTips">建议图片尺寸1920*600px，大小不超过1M</div>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  data() {
    function checkLogo(rule, value, callback) {
      if (!value) {
        callback(new Error('请上传店铺logo'));
      } else {
        callback();
      }
    }
    function checkBanner(rule, value, callback) {
      if (!value.length) {
        callback(new Error('请上传店铺banner'));
      } else {
        callback();
      }
    }
    function checkOrnament(rule, value, callback) {
      if (!value) {
        callback(new Error('请上传店铺装饰图'));
      } else {
        callback();
      }
    }
    return {
      info: {
        store_classify_id: '',
        store_name: '',
        store_logo: '',
        store_banner: [],
        store_intro: '',
        store_ornament_picture: '',
      },
      rules: {
        store_name: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
        store_classify_id: [{ required: true, message: '请选择商家分类', trigger: 'blur' }],
        store_logo: [{ required: true, validator: checkLogo, trigger: 'change' }],
        store_banner: [{ required: true, validator: checkBanner, trigger: 'change' }],
        store_ornament_picture: [{ required: true, validator: checkOrnament, trigger: 'change' }],
        store_intro: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
      },
      storeClassify: [],
    };
  },
  created() {
    this.getStoreClassify();
    this.getInfo();
  },
  methods: {
    getStoreClassify() {
      this.$axios.post(this.$api.en.storeClassify).then((res) => {
        if (res.code == 0) {
          this.storeClassify = res.result;
        }
      });
    },
    getInfo() {
      this.$axios.post(this.$api.en.storeInfo).then((res) => {
        if (res.code == 0) {
          if (res.result) {
            let info = res.result;
            this.info.store_name = info.store_name;
            this.info.store_intro = info.store_intro;
            this.info.store_logo = info.store_logo;
            this.info.store_ornament_picture = info.store_ornament_picture;
            this.info.store_classify_id = info.store_classify_id;
            this.info.store_banner = JSON.parse(info.store_banner);
          }
        }
      });
    },
    Preservation() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = _.cloneDeep(this.info);
          let path = [];
          obj.store_banner.map(item=> path.push(item.path));
          obj.store_banner = path;
          this.$axios.post(this.$api.en.editStoreInfo, obj).then((res) => {
            if (res.code === 0) {
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  font-size: 14px;
  padding-bottom: 80px;
  .topTips {
    width: 100%;
    background-color: #f2f2f2;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #1466fd;
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  .el-form {
    margin-top: 20px;
    .el-input,
    .el-select,
    .el-textarea {
      width: 500px;
    }
    .sizeTips {
      color: #999999;
      font-size: 12px;
    }
  }
}
</style>
