var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('div',{staticClass:"topTips"},[_c('div',[_vm._v("此为跨境电商PC网站的装修部分，PC端显示英文，请下面内容添加英文")]),_c('div',[_vm._v(" 网站地址： "),_c('el-link',{attrs:{"type":"primary","href":"http://shop518423.mall.qqweisou.cn","target":"_blank"}},[_vm._v("http://shop518423.mall.qqweisou.cn")])],1)]),_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.info,"status-icon":"","rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"店铺名称：","prop":"store_name"}},[_c('el-input',{model:{value:(_vm.info.store_name),callback:function ($$v) {_vm.$set(_vm.info, "store_name", $$v)},expression:"info.store_name"}})],1),_c('el-form-item',{attrs:{"label":"店铺logo：","prop":"store_logo"}},[_c('ReadyUploadSource',{attrs:{"path":_vm.info.store_logo,"showStyle":{
          width: '200px',
          height: '200px',
        }},on:{"getSource":function (val) { return (_vm.info.store_logo = val.path); },"removeThis":function($event){_vm.info.store_logo = ''}}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸200*200px，大小不超过1M")])],1),_c('el-form-item',{attrs:{"label":"商家分类：","prop":"store_classify_id"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.info.store_classify_id),callback:function ($$v) {_vm.$set(_vm.info, "store_classify_id", $$v)},expression:"info.store_classify_id"}},_vm._l((_vm.storeClassify),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.classify_name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"店铺banner：","prop":"store_banner"}},[_c('ReadyUploadSource',{attrs:{"isMany":true,"manyPath":_vm.info.store_banner,"showStyle":{
          width: '384px',
          height: '120px',
        }},on:{"changeOrder":function (list) { return (_vm.info.store_banner = list); },"getSource":function (list) {
                var ref;

                return (ref = _vm.info.store_banner).push.apply(ref, list);
},"removeThis":function (index) { return _vm.info.store_banner.splice(index, 1); }}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸1920*600px，大小不超过1M")])],1),_c('el-form-item',{attrs:{"label":"公司简介：","prop":"store_intro"}},[_c('el-input',{attrs:{"type":"textarea","rows":7},model:{value:(_vm.info.store_intro),callback:function ($$v) {_vm.$set(_vm.info, "store_intro", $$v)},expression:"info.store_intro"}})],1),_c('el-form-item',{attrs:{"label":"店铺装饰图：","prop":"store_ornament_picture"}},[_c('ReadyUploadSource',{attrs:{"path":_vm.info.store_ornament_picture,"showStyle":{
          width: '384px',
          height: '120px',
        }},on:{"getSource":function (val) { return (_vm.info.store_ornament_picture = val.path); },"removeThis":function($event){_vm.info.store_ornament_picture = ''}}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸1920*600px，大小不超过1M")])],1)],1),_c('Preservation',{on:{"preservation":_vm.Preservation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }